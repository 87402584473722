import { Artist } from '../../../types/Artist'
import { Booker } from '../../../types/Booker'

export type Users = {
  artists: Artist[]
  bookers: Booker[]
}

export enum TypeSelection {
  ALL = 'ALL',
  ARTIST = 'ARTIST',
  BOOKER = 'BOOKER',
}

export enum Type {
  ARTIST = 'ARTIST',
  BOOKER = 'BOOKER',
}
