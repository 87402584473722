import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import MediumContainer from '../../UI/Container/MediumContainer'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { DashboardValues } from './types'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatNumber, formatPrice } from '../../../utility'

const Item: React.FC<{ title: string; value: any }> = ({ title, value }) => {
  const Wrapper = styled.div`
    width: calc(100% / 3 - (15px * 2 / 3));
    @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
      width: 100%;
    }
  `
  return (
    <Wrapper className="p-3 shadow-md bg-blue-light">
      <div className="">{title}</div>
      <div className="font-bold text-lg text-center mt-2">{value}</div>
    </Wrapper>
  )
}

const Dashboard = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const getDashboardValues = functional.use(Routes.GENERATE_VALUES)
  const [values, setValues] = useState<DashboardValues>(null)

  useEffect(() => {
    const pull = async () => {
      const values = await getDashboardValues()
      setValues(values)
    }
    if (!values) pull()
  }, [values, getDashboardValues])

  return (
    <MediumContainer>
      <div className="py-10">
        <div className="text-lg font-bold">{t.admin.dashboard.title}</div>
        <div className="mt-3">
          {values && (
            <div className="flex flex-wrap gap-[15px]">
              <Item title={t.admin.dashboard.project.total} value={formatNumber(values.project.total)} />
              <Item title={t.admin.dashboard.project.active} value={formatNumber(values.project.active)} />
              <Item title={t.admin.dashboard.project.completed} value={formatNumber(values.project.completed)} />
              <Item title={t.admin.dashboard.project.avgTimeToComplete} value={values.project.avgTimeToComplete} />
              <Item title={t.admin.dashboard.project.avgRevenue} value={formatPrice(values.project.avgRevenue)} />
              <Item title={t.admin.dashboard.registration.total} value={values.registration.total} />
              <Item title={t.admin.dashboard.registration.activated} value={values.registration.activated} />
              <Item title={t.admin.dashboard.revenue.total} value={formatPrice(values.revenue.total)} />
              <Item title={t.admin.dashboard.revenue.internalFee} value={formatPrice(values.revenue.internalFee)} />
            </div>
          )}
        </div>
      </div>
    </MediumContainer>
  )
}

export default Dashboard
