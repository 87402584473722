import { Navigate, Route, Routes } from 'react-router-dom'
import Layout from '../UI/Layout/Layout'
import Tags from './Tags/Tags'
import Dashboard from './Dashboard/Dashboard'
import Users from './Users/Users'
import { Level } from '../../types/Auth'

const Register = () => {
  return (
    <Layout authLevelRequired={[Level.ADMIN]}>
      <Routes>
        <Route path={'dashboard'} element={<Dashboard />} />
        <Route path={'users'} element={<Users />} />
        <Route path={'tags'} element={<Tags />} />
        <Route path="*" element={<Navigate replace to="dashboard" />} />
      </Routes>
    </Layout>
  )
}

export default Register
