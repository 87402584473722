import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Type } from './types'
import { getArtistName, getBookerName } from '../../../utility'
import { Artist, VerificationState } from '../../../types/Artist'
import { Booker } from '../../../types/Booker'
import { CheckBadgeIcon, ExclamationCircleIcon, LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { useState } from 'react'

type Props = {
  type: Type
  data: Artist | Booker
}

const User: React.FC<Props> = ({ type, data }) => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const toggleUserActivation = functional.use(Routes.TOGGLE_USER_ACTIVATION)
  const [isActive, setIsActive] = useState<boolean>(data?.accountDetail?.active)

  const getName = () => {
    if (type === Type.ARTIST) {
      return getArtistName(data as Artist)
    }
    return getBookerName(data as Booker)
  }

  const getMail = () => {
    return data?.accountDetail?.mail
  }

  const isVerified = () => {
    if (type === Type.BOOKER) return true
    return (data as Artist).verificationState === VerificationState.VERIFIED
  }

  const toggleLock = async () => {
    const status = await toggleUserActivation({ type, uuid: data.uuid })
    if (!!status) {
      setIsActive(!isActive)
    }
  }

  return (
    <div className="flex items-center">
      <div className="w-5/12">{getName()}</div>
      <div className="w-5/12">{getMail()}</div>
      <div className="w-2/12 flex gap-5">
        <div>
          {isVerified() ? (
            <div className="tooltip" data-tip={t.admin.users.verified.YES}>
              <CheckBadgeIcon className="text-blue-primary h-5" />
            </div>
          ) : (
            <div className="tooltip" data-tip={t.admin.users.verified.NO}>
              <ExclamationCircleIcon className="text-red h-5" />
            </div>
          )}
        </div>
        <div onClick={toggleLock} className="cursor-pointer">
          {isActive ? (
            <div className="tooltip" data-tip={t.admin.users.locked.NO}>
              <LockOpenIcon className="text-blue-primary h-5" />
            </div>
          ) : (
            <div className="tooltip" data-tip={t.admin.users.locked.YES}>
              <LockClosedIcon className="text-red h-5" />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default User
