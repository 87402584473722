enum Routes {
  GET_SUGGESTED_PROJECTS = 'GET_SUGGESTED_PROJECTS',
  GET_SUGGESTED_RATINGS = 'GET_SUGGESTED_RATINGS',
  GET_LOCATION_SUGGESTION = 'GET_LOCATION_SUGGESTION',
  GET_GOOGLE_PLACE_COORDINATES = 'GET_GOOGLE_PLACE_COORDINATES',
  LIST_TAGS = 'LIST_TAGS',
  GET_File_UPLOAD_PRESIGNED_URL = 'GET_File_UPLOAD_PRESIGNED_URL',
  CHECK_USERNAME_AND_MAIL_AVAILABILITY = 'CHECK_USERNAME_AND_MAIL_AVAILABILITY',
  REGISTER_ARTIST = 'REGISTER_ARTIST',
  REGISTER_BOOKER = 'REGISTER_BOOKER',
  CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION',
  REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET',
  SET_NEW_PASSWORD = 'SET_NEW_PASSWORD',
  SEARCH_ARTISTS = 'SEARCH_ARTISTS',
  CREATE_REQUEST = 'CREATE_REQUEST',
  SEARCH_REQUESTS = 'SEARCH_REQUESTS',
  SUBMIT_OFFER = 'SUBMIT_OFFER',
  LIST_OFFERS = 'LIST_OFFERS',
  CREATE_EXTERNAL_PROJECT = 'CREATE_EXTERNAL_PROJECT',
  VERIFY_EXTERNAL_PROJECT = 'VERIFY_EXTERNAL_PROJECT',
  SEND_EXTERNAL_PROJECT_VERIFICATION_MAIL_AGAIN = 'SEND_EXTERNAL_PROJECT_VERIFICATION_MAIL_AGAIN',
  LIST_NOTIFICATIONS = 'LIST_NOTIFICATIONS',
  UPDATE_ACCOUNT_CREDENTIALS = 'UPDATE_ACCOUNT_CREDENTIALS',
  CONFIRM_NEW_MAIL = 'CONFIRM_NEW_MAIL',
  GET_NOTIFICATION_SETTINGS = 'GET_NOTIFICATION_SETTINGS',
  UPDATE_NOTIFICATION_SETTINGS = 'UPDATE_NOTIFICATION_SETTINGS',
  ADD_BANK_ACCOUNT_TO_ARTIST = 'ADD_BANK_ACCOUNT_TO_ARTIST',
  ADD_DOCUMENTS_TO_ARTIST = 'ADD_DOCUMENTS_TO_ARTIST',
  UPDATE_ARTIST_INVOICE_DETAIL = 'UPDATE_ARTIST_INVOICE_DETAIL',
  CONFIRM_PUBLIC_REQUEST_BUDGET = 'CONFIRM_PUBLIC_REQUEST_BUDGET',
  ACCEPT_REQUEST = 'ACCEPT_REQUEST',
  DECLINE_REQUEST = 'DECLINE_REQUEST',
  CREATE_PROJECT_ADD_ON = 'CREATE_PROJECT_ADD_ON',
  PROJECT_ADD_ON_DECISION = 'PROJECT_ADD_ON_DECISION',
  LIST_MILESTONES_OF_PROJECT = 'LIST_MILESTONES_OF_PROJECT',
  REMOVE_MILESTONE_OF_PROJECT = 'REMOVE_MILESTONE_OF_PROJECT',
  UPSERT_MILESTONE_OF_PROJECT = 'UPSERT_MILESTONE_OF_PROJECT',
  DECLINE_MILESTONE_COMPLETION = 'DECLINE_MILESTONE_COMPLETION',
  LIST_CHATS = 'LIST_CHATS',
  GET_LAST_CHAT_MESSAGE = 'GET_LAST_CHAT_MESSAGE',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  DECLINE_OFFER = 'DECLINE_OFFER',
  SUBMIT_PROJECT_RATING = 'SUBMIT_PROJECT_RATING',
  GET_PROJECT_RATING = 'GET_PROJECT_RATING',
  LIST_INVOICES = 'LIST_INVOICES',
  GET_INVOICE = 'GET_INVOICE',
  GET_STATISTICS = 'GET_STATISTICS',
  TRACK_ARTIST_PROFILE_VIEW = 'TRACK_ARTIST_PROFILE_VIEW',
  TOGGLE_FAVORITE = 'TOGGLE_FAVORITE',
  LIST_FAVORITES = 'LIST_FAVORITES',
  GET_FINGERPRINT = 'GET_FINGERPRINT',
  LIST_RECOMMENDATIONS = 'LIST_RECOMMENDATIONS',
  SEARCH_PROJECTS = 'SEARCH_PROJECTS',
  SEARCH_GLOBAL = 'SEARCH_GLOBAL',
  LIST_PROJECTS = 'LIST_PROJECTS',
  LIST_ARTIST_RATING = 'LIST_ARTIST_RATING',
  UPDATE_PROJECT = 'UPDATE_PROJECT',
  REMOVE_REQUEST_TO_ARTIST = 'REMOVE_REQUEST_TO_ARTIST',
  TRANSFER_DRAFT_TO_REQUEST = 'TRANSFER_DRAFT_TO_REQUEST',
  SET_PROJECT_VISIBILITY = 'SET_PROJECT_VISIBILITY',

  // admin routes
  GENERATE_VALUES = 'GENERATE_VALUES',
  LIST_USERS = 'LIST_USERS',
  TOGGLE_USER_ACTIVATION = 'TOGGLE_USER_ACTIVATION',
}

export default Routes
