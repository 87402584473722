import Location from './Location'
import { Price } from './Pricing'
import { Sizes } from './Size'

export enum VerificationState {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
}

export enum BusinessType {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
}

export enum IdentityCardStatus {
  MISSING = 'MISSING',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
  DECLINED = 'DECLINED',
}

export enum BankAccountStatus {
  MISSING = 'MISSING',
  PENDING = 'PENDING',
  VERIFIED = 'VERIFIED',
}

export type AccountDetail = {
  mail?: string
  active: boolean
}

export type PersonalDetail = {
  username: string
}

export type LocationDetail = {
  location: Location
  radius?: number
  nationwide: boolean
}

export type Skills = {
  tagENUMList: string[]
}

export type Story = {
  story: string
}

export type ProfileImages = {
  personImageKey: string
  personImageURL: string
  backgroundImageKey: string
  backgroundImageURL: string
}

export type Portfolio = {
  keys: string[]
  urls?: string[]
}

export type Pricing = {
  art: {
    [Sizes.S]: Price
    [Sizes.M]: Price
    [Sizes.L]: Price
  }
  primer: {
    [Sizes.S]?: Price
    [Sizes.M]?: Price
    [Sizes.L]?: Price
    disabled: boolean
  }
  concept: {
    price: Price
  }
  workshop: {
    pricePerAttendee: Price
    minAttendees: number
  }
  downPaymentPercentage: number
  hidden?: boolean
}

export type InsuranceDetail = {
  enabled: boolean
  radius: number
  pricePercentage: number
  timespanInMonths: number
  timeExpenseInHours: number
}

export type BillingDetail = {
  businessType: BusinessType
  firstname: string
  lastname: string
  phone: string
  birthday: Date
  companyName?: string
  street: string
  postcode: string
  city: string
  countryCode: string
  currency: string
}

export type InvoiceDetail = {
  smallBusinessRegulationActive: boolean
  taxNumber?: string
  taxID?: string
  registerNumber?: string
  registerName?: string
  ibanLast4Digits?: string
  bankname?: string
  stripe?: {
    accountID?: string
    // in case of an business account
    personID?: string
    identityCardStatus?: IdentityCardStatus
    bankAccountStatus?: BankAccountStatus
  }
}

export type Artist = {
  uuid: string
  invoiceIdentificationNumber: number
  verificationState: VerificationState
  accountDetail?: AccountDetail
  personalDetail: PersonalDetail
  locationDetail: LocationDetail
  skills: Skills
  story: Story
  profileImages: ProfileImages
  portfolio: Portfolio
  pricing: Pricing
  insuranceDetail: InsuranceDetail
  billingDetail?: BillingDetail
  invoiceDetail?: InvoiceDetail
  rating: {
    projectAmount: number
    ratingAmount: number
    rating: number
  }
  isFavorite?: boolean
}
