import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import MediumContainer from '../../UI/Container/MediumContainer'
import { functional } from '@think-internet/zeus-frontend-package'
import Routes from '../../../redux/routes'
import { useEffect, useState } from 'react'
import { Type, TypeSelection, Users as UsersType } from './types'
import { Artist } from '../../../types/Artist'
import Input from '../../UI/NewInput/NewInput'
import Dropdown from '../../UI/Dropdown/Dropdown'
import User from './User'
import { Booker } from '../../../types/Booker'
import { getArtistName, getBookerName } from '../../../utility'

const Users = () => {
  const t = useSelector((s) => s[props.TRANSLATION])
  const listUsers = functional.use(Routes.LIST_USERS)
  const [users, setUsers] = useState<UsersType>(null)
  const [search, setSearch] = useState('')
  const [typeSelection, setTypeSelection] = useState<TypeSelection>(TypeSelection.ALL)

  useEffect(() => {
    const pull = async () => {
      const users = await listUsers({})
      setUsers(users)
    }
    if (!users) {
      pull()
    }
  }, [users, listUsers])

  const artistFilter = (artist: Artist) => {
    if (!artist) return false
    if (typeSelection !== TypeSelection.ALL && typeSelection !== TypeSelection.ARTIST) return false
    if (!!search)
      return (
        getArtistName(artist).toLowerCase().includes(search.toLowerCase()) || artist.accountDetail.mail.toLowerCase().includes(search.toLowerCase())
      )
    return true
  }
  const bookerFilter = (booker: Booker) => {
    if (!booker) return false
    if (typeSelection !== TypeSelection.ALL && typeSelection !== TypeSelection.BOOKER) return false
    if (!!search)
      return (
        getBookerName(booker).toLowerCase().includes(search.toLowerCase()) || booker.accountDetail.mail.toLowerCase().includes(search.toLowerCase())
      )
    return true
  }

  if (!users) return null
  return (
    <MediumContainer>
      <div className="py-10">
        <div className="text-lg font-bold">{t.admin.users.title}</div>
        <div className="mt-3">
          <div className="flex gap-3">
            <Input value={search} onChange={setSearch} placeholder={t.admin.users.search} />
            <Dropdown
              className="!w-1/3"
              showEmptySelection={false}
              onChange={setTypeSelection}
              value={typeSelection}
              items={Object.values(TypeSelection).map((c) => ({ value: c, label: t.admin.users.typeSelection[c] }))}
            />
          </div>
          <div className="flex flex-col mt-5 gap-2">
            {users.artists.filter(artistFilter).map((a) => (
              <User type={Type.ARTIST} data={a} key={a.uuid} />
            ))}
            {users.bookers.filter(bookerFilter).map((a) => (
              <User type={Type.BOOKER} data={a} key={a.uuid} />
            ))}
          </div>
        </div>
      </div>
    </MediumContainer>
  )
}

export default Users
